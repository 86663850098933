import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input
} from "@chakra-ui/core"
import { groupBy, startCase } from "lodash"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1, H4 } from "../components/Typography"
import firebase from "gatsby-plugin-firebase"
import BusinessListingCard from "../components/BusinessListingCard"
import Fuse from "fuse.js"

export default function BusinessListingListTemplate({
  data,
  pageContext,
  ...rest
}) {
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [searchText, setSearchText] = useState("")
  const businessListings =
    data.allBusinessListing.edges.map(edge => edge.node).filter(item => {
      return item.approvalStatus === undefined || item.approvalStatus === null || item.approvalStatus === 'approved'
    }).sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    }) || []
  const fullListings = data.fullBLlistings.edges.map(edge => edge.node).filter(item => {
    return item.approvalStatus === undefined || item.approvalStatus === null || item.approvalStatus === 'approved'
  }).sort((a, b) => {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  }) || []
  const { currentPage, numPages } = pageContext || {}
  const hasMore = numPages > currentPage
  const hasLess = currentPage > 1

  const allcategories = data.categories.edges.map(edge => edge.node.name)
  const singleList = new Set(allcategories)
  const categories = Array.from(singleList).sort()

  const options = {
    // Search in `author` and in `tags` array
    keys: ["name"],
  }

  const sortedBusinessListings =
    selectedCategory !== "all"
      ? fullListings.filter(
        bl => {
          const blCatList = bl.businessListingCategories.map(cat => cat.name) || []
          return blCatList.find(blcat => blcat === selectedCategory)
        }
      )
      : businessListings

  const fuse = new Fuse(sortedBusinessListings, options)

  const result = fuse.search(searchText)
  const filteredListings = fullListings.filter((listing) => {
    const regex = new RegExp(searchText, "i");
    return regex.test(listing.name);
  });
  
  useEffect(() => {
    firebase.analytics().setCurrentScreen(`listings_page_${currentPage}`)
  }, [])

  return (
    <Layout p={[4, 6]}>
      <SEO
        title="Business Listings"
        description="Laudium Today online directory connects you with businesses in your community. It's your first and last stop when searching your local area for a restaurant, a store, home based or even a plumber!"
      />
      <H1 textAlign="center">Business Listings</H1>
      <Flex mt={4} width="100%" direction="column">
        <Flex
          width="100%"
          background="linear-gradient(100deg, #64b9ed,#1b95e0);"
          borderRadius={4}
          p={4}
          color="white"
          direction={["column", "row"]}
        >
          <FormControl width="100%">
            <FormLabel htmlFor="category">Category:</FormLabel>
            <Select
              placeholder="Select category"
              backgroundColor="white"
              id="category"
              name="category"
              value={selectedCategory}
              color="black"
              onChange={e => setSelectedCategory(e.target.value)}
            >
              <option key="alll" value="all">
                All
              </option>
              {categories.map(category => (
                <option key={category} value={category}>
                  {startCase(category)}
                </option>
              ))}
            </Select>
          </FormControl>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <FormControl width="100%">
            <FormLabel htmlFor="keywords">Keywords:</FormLabel>
            <Input
              variant="outline"
              type="text"
              id="keywords"
              name="keywords"
              backgroundColor="white"
              color="black"
              value={searchText}
              onChange={e => setSearchText(e.target.value || "")}
            />
          </FormControl>
        </Flex>
        <Flex
          mt={8}
          wrap="wrap"
          style={{ gap: "24px" }}
          justify="space-between"
        >
          {searchText.length > 0 ? (
            <>
              {filteredListings.length > 0 ? (
                <>
                  <H4>{filteredListings.length} Results</H4>
                  {filteredListings.map(listing => (
                    <BusinessListingCard listing={listing} url={`https://www.laudiumtoday.co.za/listings/${listing.fields.slug}`} />
                  ))}
                </>
              ) : (
                <H4>No Results</H4>
              )}
            </>
          ) : (
            <>
              {sortedBusinessListings.map(listing => {
                return (
                  <BusinessListingCard listing={listing} url={`https://www.laudiumtoday.co.za/listings/${listing.fields.slug}`} />
                )
              })}
            </>
          )}
        </Flex>
      </Flex>
      <Flex justify="center" align="center" w="100%" mt={8}>
        <Button
          isDisabled={!hasLess}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage > 2) {
              navigate(`/listings/${currentPage - 1}`)
            } else navigate(`/listings`)
          }}
        >
          Previous
        </Button>
        <Flex marginX={4}>
          Page {currentPage} of {numPages}
        </Flex>
        <Button
          isDisabled={!hasMore}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage < numPages) {
              navigate(`/listings/${currentPage + 1}`)
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query allBusinessListingList($skip: Int!, $limit: Int!) {
    allBusinessListing(
      skip: $skip
      limit: $limit
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          address
          approvalStatus
          brochureUrl
          businessListingCategories {
            name
          }
          fields {
            slug
          }
          contactNumber
          alternateContactNumber
          emailAddress
          id
          logoUrl
          name
          description
          videoLink
          website
          whatsapp
          facebook
          instagram
          twitter
          tradingHours {
            Monday {
              closed
              end
              day
              start
              index
            }
            Tuesday {
              closed
              day
              end
              index
              start
            }
            Wednesday {
              closed
              day
              end
              index
              start
            }
            Thursday {
              closed
              day
              end
              index
              start
            }
            Friday {
              closed
              day
              end
              index
              start
              additional
            }
            Friday_Additional {
              closed
              day
              end
              index
              start
            }
            Saturday {
              closed
              day
              end
              index
              start
            }
            Sunday {
              closed
              day
              end
              index
              start
            }
          }
        }
      }
    }
    fullBLlistings: allBusinessListing {
      edges {
        node {
          address
          approvalStatus
          brochureUrl
          businessListingCategories {
            name
          }
          fields {
            slug
          }
          contactNumber
          alternateContactNumber
          emailAddress
          id
          logoUrl
          name
          description
          videoLink
          website
          whatsapp
          facebook
          instagram
          twitter
          tradingHours {
            Monday {
              closed
              end
              start
              index
            }
            Tuesday {
              closed
              day
              end
              index
              start
            }
            Wednesday {
              closed
              day
              end
              index
              start
            }
            Thursday {
              closed
              day
              end
              index
              start
            }
            Friday {
              closed
              day
              end
              index
              start
              additional
            }
            Friday_Additional {
              closed
              day
              end
              index
              start
            }
            Saturday {
              closed
              day
              end
              index
              start
            }
            Sunday {
              closed
              day
              end
              index
              start
            }
          }
        }
      }
    }
    categories: allBusinessListingategories {
    edges {
      node {
        id
        name
      }
    }
  }
  }
`
